<template>
	<v-card>
		<v-dialog v-model="dialogErro.status" persistent>
			<CardDialog :dialog="dialogErro" />
		</v-dialog>
		<v-card-title>
			<v-row no-gutters class="mb-n8">
				<v-col class="d-none d-md-block pr-1">
					<InputDatePicker :outlined="true" :dense="true" v-model="busca.dtinicio" label="Data inicial" />
				</v-col>
				<v-col class="d-none d-md-block px-1">
					<InputDatePicker :outlined="true" :dense="true" v-model="busca.dtfim" label="Data final" />
				</v-col>
				<v-col class="d-none d-md-block px-1">
					<v-autocomplete
						:items="filiaisFiltro"
						clearable
						item-text="filial"
						item-value="idfilial"
						label="Filial"
						outlined
						dense
						:disabled="carregando"
						v-model="busca.idfilial"
					></v-autocomplete>
				</v-col>
				<v-bottom-sheet class="d-inline-flex d-md-none" v-model="sheet">
					<v-sheet class="text-center" height="100%">
						<v-row class="pa-3">
							<v-col cols="12" class="pr-3 pr-md-0 mb-n8">
								<v-autocomplete
									:items="filiaisFiltro"
									clearable
									item-text="filial"
									item-value="idfilial"
									label="Filial"
									outlined
									dense
									:disabled="carregando"
									v-model="busca.idfilial"
								></v-autocomplete>
							</v-col>
							<v-col cols="6">
								<InputDatePicker
									v-model="busca.dtinicio"
									label="Data inicial"
									:outlined="true"
									:dense="true"
								/>
							</v-col>
							<v-col cols="6">
								<InputDatePicker v-model="busca.dtfim" label="Data final" :outlined="true" :dense="true" />
							</v-col>
							<v-col cols="6">
								<v-btn text class="mt-n3" color="primary" @click="sheet = !sheet">Cancelar</v-btn>
							</v-col>
							<v-col cols="6">
								<v-btn text class="mt-n3" color="primary" @click="sheet = !sheet">Filtrar</v-btn>
							</v-col>
						</v-row>
					</v-sheet>
				</v-bottom-sheet>
				<v-col class="pl-1">
					<v-text-field
						class="d-flex d-md-none"
						v-model="busca.busca"
						label="Buscar"
						outlined
						dense
						append-outer-icon="mdi-menu"
						@click:append-outer="sheet = !sheet"
					/>
					<v-text-field class="d-none d-md-block" v-model="busca.busca" label="Buscar" outlined dense />
				</v-col>
				<v-col cols="auto" class="ml-2">
					<v-btn
						:disabled="carregando"
						class="mt-1"
						color="primary"
						elevation="0"
						fab
						x-small
						@click="listar(0)"
					>
						<v-icon>mdi-magnify</v-icon>
					</v-btn>
				</v-col>
			</v-row>
		</v-card-title>
		<v-divider />
		<v-progress-linear indeterminate absolute :active="carregando" />
		<v-card-text>
			<v-skeleton-loader v-if="carregandoSkeleton" type="table-tbody" />
			<v-simple-table v-else>
				<template v-slot:default>
					<thead>
						<tr>
              <th>Empresa</th>
							<th>Cliente</th>
							<th>Pedido</th>
							<th>Valor</th>
							<th>Data</th>
							<th class="text-center">Cupons Gerados</th>
							<th class="text-center">Cupons Impressos</th>
							<th class="text-center">Status Pedido</th>
							<th class="text-center">Status Cresce Vendas</th>
							<th>Vendedor</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<template v-if="dados.total > 0">
							<tr v-for="(u, i) in dados.lista" :key="i">
                <td class="text-truncate">{{u.idempresa}} - {{u.empresa | formataTextoCurto}}</td>
								<td class="text-truncate">{{u.idclifor}} - {{u.nome | formataTextoCurto}}</td>
								<td>{{u.idPedido}}</td>
								<td>{{u.venda | formataDinheiro}}</td>
								<td>{{u.dtmovimento}}</td>
								<td class="text-center">{{u.qtdcupons}}</td>
								<td class="text-center">{{u.qtdcuponsimpresso}}</td>
								<td class="text-center font-weight-bold">
									<v-chip v-if="u.pedidoimportado" small color="success">OK</v-chip>
									<v-chip v-else small color="error">Aberto</v-chip>
								</td>
								<td class="text-center font-weight-bold">
									<v-chip v-if="u.flagclientecadastrado" small color="success">Cadastrado</v-chip>
									<v-chip v-else small color="error">Sem Cadastro</v-chip>
								</td>
								<td>{{u.idvendedor}} - {{u.vendedor | formataTextoCurto}}</td>
								<td class="text-center">
									<v-btn
										v-if="usuario.idgrupo == 1"
										:disabled="!u.flagclientecadastrado || !u.pedidoimportado || u.qtdcupons == 0 || carregando"
										class="mr-1"
										elevation="0"
										fab
										color="primary"
										x-small
										@click="reimprimir(u)"
									>
										<v-icon>mdi-printer-outline</v-icon>
									</v-btn>
								</td>
							</tr>
						</template>
						<template v-else>
							<tr>
								<td class="text-center" colspan="9">Nenhum registro encontrado.</td>
							</tr>
						</template>
					</tbody>
				</template>
			</v-simple-table>
			<Paginacao
				:total="dados.total"
				@atualizar="listar"
				:carregando="carregando"
				:pg="pgSelecionada"
			/>
		</v-card-text>
		<v-progress-linear indeterminate absolute :active="carregando" />
	</v-card>
</template>

<script>
import { mapState } from "vuex";
import CardDialog from "../Widgets/CardDialog.vue";
import InputDatePicker from "../Widgets/InputDatePicker";
import Paginacao from "../Widgets/Paginacao.vue";
import mixinFilial from "../../util/mixinFilial";
import axios from "axios";

export default {
	name: "SorteioLista",
	mixins: [mixinFilial],
	components: { InputDatePicker, Paginacao, CardDialog },
	data: () => ({
		sheet: false,
		carregando: false,
		carregandoSkeleton: true,
		dialog: false,
		dialogErro: {
			status: false,
			title: "",
			msg: "",
			icon: "",
			iconColor: "",
		},
		dados: {},
		busca: {
			dtinicio: `${new Date()
				.toLocaleDateString("pt-BR")
				.split("/")
				.reverse()
				.join("-")}`,
			dtfim: `${new Date()
				.toLocaleDateString("pt-BR")
				.split("/")
				.reverse()
				.join("-")}`,
			idfilial: null,
		},
		pgSelecionada: 0,
	}),
	computed: {
		...mapState(["backendUrl", "pgLimit", "usuario"]),
		filiaisFiltro() {
			const idsFiliaisExcluidas = [7,11,12,14,15,20,27,28,30,35,36];

			if (this.filiais.lista) {
				return this.filiais.lista.filter(
					(filial) => idsFiliaisExcluidas.includes(filial.idfilial)
				);
			}

			return [];
		},
	},
	methods: {
		listar(pg) {
			this.pgSelecionada = pg;
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}sorteio/listar`, {
					limit: this.pgLimit,
					offset: this.pgLimit * pg,
					busca: this.busca.busca || null,
					dtini: this.busca.dtinicio || null,
					dtfim: this.busca.dtfim || null,
					idempresa: this.busca.idfilial || null,
				})
				.then((res) => {
					this.dados = res.data;
					this.carregando = false;
					this.carregandoSkeleton = false;
				})
				.catch(() => {
					this.dados = { total: 0 };
					this.carregando = false;
					this.carregandoSkeleton = false;
				});
		},
		reimprimir(pedido) {
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}sorteio/reimprimir`, {
					idpedido: pedido.idPedido,
					idempresa: pedido.idempresa,
				})
				.then((res) => {
					this.carregando = false;
					if (res.data) {
						this.dialogErro.title = "Sucesso";
						this.dialogErro.msg = "Cupom reimpresso com sucesso!";
						this.dialogErro.icon = "mdi-check-circle-outline";
						this.dialogErro.iconColor = "success";
						this.dialogErro.status = true;
					} else {
						this.dialogErro.title = "Erro";
						this.dialogErro.msg =
							"Não foi possível realizar a reimpressão do cupom!";
						this.dialogErro.icon = "mdi-alert-circle-outline";
						this.dialogErro.iconColor = "red";
						this.dialogErro.status = true;
					}
				})
				.catch((e) => {
					this.carregando = false;
					this.dialogErro.title = "Erro";
					this.dialogErro.msg =
						this.erroFormatar(e) ||
						"Não foi possível realizar a reimpressão do cupom!";
					this.dialogErro.icon = "mdi-alert-circle-outline";
					this.dialogErro.iconColor = "red";
					this.dialogErro.status = true;
				});
		},
		async init() {
			await this.listar(0);
		},
	},
	watch: {
		"busca.dtfim": function () {
			if (this.busca.dtfim < this.busca.dtinicio) {
				this.busca.dtinicio = this.busca.dtfim;
			}
		},
		"busca.dtinicio": function () {
			if (this.busca.dtfim < this.busca.dtinicio) {
				this.busca.dtfim = this.busca.dtinicio;
			}
		},
	},
	created() {
		this.init();
	},
};
</script>

<style scoped>
</style>